import * as React from "react";
import Container from "../../components/container";
import Layout from "../../components/layout";
import VideoPatientJourney from "../../assets/EF_Video_PatientJourney_Portale_07_mitAbspann-resize.mp4"
import VideoSpitalJourney from "../../assets/DoctorsJourney_TIE_Portale_3-resize-video.mp4"


// import { StaticImage } from "gatsby-plugin-image";
//import HubspotForm from "react-hubspot-form";
import {
  mainContent,
  introSection,
  //description,
  row,
  //col6,
  col12,
  marginTopSubstitute,
  //hubSpotForm,
} from "../subpage.module.scss";

const PatientJourneyPage = () => {
  return (
    <Layout pageTitle="Patient Journey" neutralPage={true}>
      <main className={mainContent}>
        <section className={introSection}>
        <Container oneColumn={true}>
            <div className={row}>
              <div className={col12}>

                <h1>Videos zur ti–e Portalwelt</h1>
                <p className="text-medium">
                Nachfolgend zwei animierte Videos, die das Zusammenspiel von ti–e Zuweiserportal, ti–e Klinikportal und ti–e Patientenportal in der Patient-Journey von Kathrin Huber zeigen.
                </p>

                
                <p>Aus Sicht des Patienten:</p>
                <video controls>
                  <source src={VideoPatientJourney} type="video/mp4"/>
                  <track src="captions_en.vtt" kind="captions" srclang="de" label="md-akte-deutsch"></track>
                </video>

                <p className={marginTopSubstitute}>Aus Sicht des Spitals:</p>
                <video controls>
                  <source src={VideoSpitalJourney} type="video/mp4"/>
                  <track src="captions_en.vtt" kind="captions" srclang="de" label="md-akte-deutsch"></track>
                </video>

                </div>

            </div>



           
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default PatientJourneyPage;
